import { init, BrowserTracing, captureException } from '@sentry/sveltekit';
import { PUBLIC_ENV } from '$env/static/public';
import { SENTRY_DSN } from '$lib/constants';
import type { HandleClientError } from '@sveltejs/kit';
import { GENERIC_ERROR } from '$lib/constants/text';

init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],
  enabled: true,
  sampleRate: 0.01,
  tracesSampleRate: 0.01,
  environment: PUBLIC_ENV
});

export const handleError = (async ({ error, event }) => {
  const errorId = crypto.randomUUID();
  captureException(error, { extra: { event, errorId } });
  // eslint-disable-next-line no-console
  console.log('error client', error);
  return {
    message: GENERIC_ERROR,
    errorId
  };
}) satisfies HandleClientError;
